const gearData = {
  hpPavillion: {
    comp:
      "https://www.amazon.com/HP-Touchscreen-Computer-Quad-Core-i7-7500U/dp/B08BWM3RK8/ref=sr_1_2_sspa?dchild=1&keywords=hp+pavilion+laptop&qid=1599256890&sr=8-2-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExTTFOT042ODlPUlg5JmVuY3J5cHRlZElkPUEwNDIxNDk5WEdXR1VNNlNUVk9TJmVuY3J5cHRlZEFkSWQ9QTA4NTg1MDMyWldOT1dBOUNRMTU1JndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==",
    image:
      "https://images-na.ssl-images-amazon.com/images/I/61AXJ9bRjuL._AC_SL1000_.jpg",
  },

  hpPavillion2: {
    comp:
      "https://www.amazon.com/HP-15-Inch-i5-1035G1-Solid-State-15-dy1036nr/dp/B085B5SXLY/ref=sr_1_4?crid=1BI5ZF8AIOEES&dchild=1&keywords=hp+pavillion+laptop&qid=1599257426&sprefix=hp+pavilli%2Caps%2C239&sr=8-4",
    image:
      "https://images-na.ssl-images-amazon.com/images/I/71WSt8D7KFL._AC_SL1500_.jpg",
  },
}

export default gearData
