import React from "react"
import styled from "styled-components"
import gearData from "../data/gear"

import Layout from "../components/Layout"
import FullContainer from "../components/common/FullContainer"

const GearContainer = styled.div`
  display: flex;
  flex-direction: column;
  a {
    decoration: none;
    cursor: pointer;
  }
`

const PercentageFullContainer = styled(FullContainer)`
  height: 100%;
`

const Image = styled.img`
  height: 400px;
  margin-top: 1em;

  @media (max-width: 1200px) {
    height: 300px;
  }

  @media (max-width: 700px) {
    height: 200px;
  }

  @media (max-width: 600px) {
    height: 100px;
  }
`

const Gear = () => {
  return (
    <>
      <Layout>
        <FullContainer>
          <GearContainer>
            <h1>Get some gear that will help you be more productive</h1>
            <h3>Explore these laptops for programming:</h3>
            <a href={gearData.hpPavillion.comp}>
              <Image src={gearData.hpPavillion.image}></Image>
            </a>
            <a href={gearData.hpPavillion2.comp}>
              <Image src={gearData.hpPavillion2.image}></Image>
            </a>
          </GearContainer>
        </FullContainer>
      </Layout>
    </>
  )
}

export default Gear
